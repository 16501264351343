//
//
//

import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";

export default {
  data() {
    return {};
  },
  created() {
    //1 2 供应商 ， 3 4 分销商 5 平台 6游客 7个人分销
    get_user_info().then(res => {
      let type = res.userVo.userType;
      let id = res.userVo.companyId;
      if (type == 1 || type == 2) {
        this.$router.push({
          name: "supplier-registration",
          query: { type: "auditError", id: id }
        });
      } else if (type == 3 || type == 4) {
        this.$router.push({
          name: "distributor-registration",
          query: { type: "auditError", id: id }
        });
      } else if (type == 7) {
        this.$router.push({
          name: "distributor-registration",
          query: { type: "3" }
        });
      }
    });
  }
};
